<div *ngIf="onboardingData && userData" class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white border-0">
    <div class="flex-auto px-4 pb-2 lg:px-10 py-10 pt-8">
        <div class="flex flex-wrap pb-4">
            <h6 class="text-blueGray-500 text-xs mb-6 font-semibold uppercase text-center w-full">
                <span *ngIf="onboardingComplete">
                    Primeiros passos
                </span>
                <span *ngIf="!onboardingComplete">
                    🎉&ensp;Tudo Pronto!
                </span>
            </h6>

            <ul class="w-full list-disc text-blueGray-600 text-xs">
                <li [routerLink]="['/admin/meu-perfil']" class="{{checkedValue('profile')}} text-blueGray-400 mb-2 li-default cursor-pointer">
                    <i class="fas fa-circle mr-2"></i>
                    <i class="fas fa-check-circle mr-2"></i>
                    <strong>Configurar perfil profissional</strong>
                </li>
                <li [routerLink]="['/admin/minhas-contas']" class="{{checkedValue('bank')}} text-blueGray-400 mb-2 li-default cursor-pointer">
                    <i class="fas fa-circle mr-2"></i>
                    <i class="fas fa-check-circle mr-2"></i>
                    <strong>Configurar conta bancária</strong>
                </li>
                <li [routerLink]="['/admin/oportunidades-abertas']" class="{{checkedValue('aplies')}} text-blueGray-400 mb-2 li-default cursor-pointer">
                    <i class="fas fa-circle mr-2"></i>
                    <i class="fas fa-check-circle mr-2"></i>
                    <strong>Candidate-se a uma vaga</strong>
                </li>
                <li [routerLink]="['/admin/meus-contratos']" class="{{checkedValue('contracts')}} text-blueGray-400 mb-2 li-default cursor-pointer">
                    <i class="fas fa-circle mr-2"></i>
                    <i class="fas fa-check-circle mr-2"></i>
                    <strong>Feche um contrato</strong>
                </li>
            </ul>
        </div>
    </div>
</div>