<div
  class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg"
>
  <div class="flex-auto p-4">
    <div class="flex flex-wrap">
      <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
        <h5 class="text-blueGray-400 uppercase font-bold text-xs">
          {{ statSubtitle }}
        </h5>
        <span *ngIf="!isMoney" class="font-semibold text-xl text-blueGray-700">
          {{ statTitle }}
        </span>

        <span *ngIf="isMoney" class="font-semibold text-xl text-blueGray-700">
          <small class="text-blueGray-500 text-sm mr-1">R$</small>{{ statTitle | currency: '':'' }}
        </span>
      </div>
      <div class="relative w-auto pl-4 flex-initial">
        <div
          class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full"
          [ngClass]="[statIconColor]"
        >
          <i class="{{statPercentColor}}" [ngClass]="statIconName"></i>
        </div>
      </div>
    </div>
    <p class="text-xs text-blueGray-400 mt-4">
      <span class="whitespace-nowrap uppercase">
        {{ statDescripiron }}
      </span>
    </p>
  </div>
</div>
