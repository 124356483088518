<app-editable-painel [hideEdit]="hideEdit" [editing]="editMilestone" [title]="getTitle()"
    (isEditing)="setEditing($event)" (onSave)="triggerSaveOrEdit()" [hideCancel]="hideCancel">

    <h4 *ngIf="isMilestoneDone(milestoneData?.statusCode)" class="text-green-500 mb-5 uppercase font-semibold">
        <i class="fas fa-check"></i>&ensp;Entrega concluída
    </h4>

    <app-editable-input-block [value]="milestoneData.title" title="Titulo da Entrega" [editing]="editMilestone">
        <input type="text" [value]="milestoneData.title" #title (change)="setValue('title', title.value)"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
            placeholder="Insira o titulo" />
    </app-editable-input-block>

    <app-editable-input-block [value]="milestoneData.description" title="Descrição da Entrega"
        [editing]="editMilestone">
        <textarea type="text" #description (change)="setValue('description', description.value)"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
            rows="2">{{milestoneData.description}}</textarea>
    </app-editable-input-block>

    <app-editable-input-block [value]="milestoneData.dor" title="[D.O.R] - Definição de preparado"
        [editing]="editMilestone">
        <textarea type="text" #dor (change)="setValue('dor', dor.value)"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
            rows="2">{{milestoneData.dor}}</textarea>
        <small>Saiba mais em: <a target="_blank"
                href="https://www.lumis.com.br/a-lumis/blog/dor-e-dod.htm">www.lumis.com.br/a-lumis/blog/dor-e-dod.htm</a></small>
    </app-editable-input-block>

    <app-editable-input-block [value]="milestoneData.dod" title="[D.O.D] - Definição de concluído"
        [editing]="editMilestone">
        <textarea type="text" #dod (change)="setValue('dod', dod.value)"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
            rows="2">{{milestoneData.dod}}</textarea>
        <small>Saiba mais em: <a target="_blank"
                href="https://www.lumis.com.br/a-lumis/blog/dor-e-dod.htm">www.lumis.com.br/a-lumis/blog/dor-e-dod.htm</a></small>
    </app-editable-input-block>

    <app-editable-input-block isCurrency="true" [value]="milestoneData.amount" title="Valor da Entrega"
        [editing]="editMilestone">
        <input type="text" [(ngModel)]="selectedAmount" currencyMask
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
            class="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-white rounded text-lg shadow focus:outline-none focus:ring w-full ease-linear"
            placeholder="Insira o valor a ser pago pela milestone/entrega" />
    </app-editable-input-block>

    <app-editable-input-block isDate="true" [value]="milestoneData?.dueDate" title="Data prevista de conclusão"
        [editing]="editMilestone">
        <input type="date" [value]="milestoneData?.dueDate" #dueDate (change)="setValue('dueDate', dueDate.value)"
            class="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
            placeholder="Insira data prevista de conclusão" />
    </app-editable-input-block>

    <div *ngIf="!isMilestoneDone(milestoneData?.statusCode)" class="w-full lg:w-12/12 pb-4 px-4 input-block">
        <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs mb-2">
                Arquivar / Remover Entrega
            </label>

            <button (click)="archiveMilestone(milestoneData?.id)"
                class="text-red-500 bg-transparent border border-solid border-red-500 font-bold uppercase text-xs px-3 py-1 rounded outline-none mr-1 mb-1"
                type="button">
                Arquivar Entrega
            </button>
        </div>
    </div>

    <div *ngIf="!isMilestoneDone(milestoneData?.statusCode)" class="w-full lg:w-12/12 pb-4 px-4 input-block">
        <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs mb-2">
                Concluir Entrega
            </label>

            <button (click)="doneMilestone(milestoneData?.id)"
                class="text-green-500 bg-transparent border border-solid border-green-500 font-bold uppercase text-xs px-3 py-1 rounded outline-none mr-1 mb-1"
                type="button">
                Concluir Entrega
            </button>
        </div>
    </div>

    <div *ngIf="isMilestoneDone(milestoneData?.statusCode)" class="w-full lg:w-12/12 pb-4 px-4 input-block">
        <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs mb-2">
                Reverter conclusão da entrega
            </label>

            <button (click)="openMilestoneAgain(milestoneData?.id)"
                class="text-orange-500 bg-transparent border border-solid border-oragen-500 font-bold uppercase text-xs px-3 py-1 rounded outline-none mr-1 mb-1"
                type="button">
                Abrir Entrega Novamente
            </button>
        </div>
    </div>
    

</app-editable-painel>