<div *ngIf="!loading" class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
    <div class="rounded-t mb-0 px-2 py-3 border-0">
        <div class="flex flex-wrap items-center">
            <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 class="font-semibold uppercase text-xs text-blueGray-700">Contratos Pendentes</h3>
            </div>
            <div class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                <button [routerLink]="['/admin/contratos']"
                    class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button">
                    Ver todos ({{contractsListSize}})
                </button>
            </div>
        </div>
    </div>
    <div class="block w-full overflow-x-auto">

        <h3 *ngIf="!contractsList || contractsList.length === 0" class="text-center p-5">
            <span class="text-sm text-blueGray-500">Nenhum contrato pendente :)</span><br />
        </h3>

        <table *ngIf="contractsList && contractsList.length !== 0"
            class="items-center w-full bg-transparent border-collapse">
            <thead>
                <tr>
                    <th
                        class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Contratado
                    </th>
                    <th
                        class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Status
                    </th>
                    <th
                        class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">

                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let contract of contractsList">
                    <th
                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                        <span *ngIf="contract.contractor">
                            #{{contract.id}} - {{contract?.contractor.full_name}}<br />
                            <small class="font-normal">{{contract.contractName}}</small><br />
                        </span>
                        <span class="font-normal" *ngIf="!contract.contractor">
                            #{{contract.id}} - {{contract.contractName}}<br />
                            <small style="font-weight: bold;" class="text-orange-500">(Aguardando
                                Contratado)</small><br />
                        </span>
                    </th>
                    <td
                        class="{{contractStatus[contract.status].color}} uppercase border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {{contractStatus[contract.status].label}}<br />
                        <small>({{contractStatus[contract.status].sub_label}})</small>
                    </td>
                    <td [routerLink]="['/admin/editar-contrato', contract.id]"
                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <a style="font-size: 11px;" class="text-indigo-600 text-xs font-semibold cursor-pointer">VER
                            CONTRATO</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>