<div *ngIf="squadsList" class="w-full h-screen mb-4 px-4">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
        <div class="rounded-t mb-0 px-4 py-3 border-0">
            <div class="flex flex-wrap items-center">
                <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 class="font-semibold uppercase text-lg text-blueGray-700">
                        Squads
                    </h3>
                </div>
                <div class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                    <button [routerLink]="['/admin/squads/request']" 
                        class="text-white bg-indigo-500 text-xs font-bold capitalize px-3 py-1 rounded outline-none ml-2 mr-1 mb-1"
                        type="button">
                        <i class="fa fa-plus"></i>
                        <span class="ml-2">Solicitar Squad</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="block w-full overflow-x-auto">
            <table class="items-center w-full bg-transparent border-collapse">
                <thead>
                    <tr>
                        <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                            Projeto
                        </th>
                        <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                            Status
                        </th>
                        <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap text-left font-semibold bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                            Equipe
                        </th>
                        <!-- <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap text-left font-semibold bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                        </th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let squad of squadsList">
                        <td
                            class="border-t-0 text-md font-semibold px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">
                            {{squad.projectName}}
                        </td>
                        <td
                            class="border-t-0s font-bold px-6 align-middle capitalize border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <span>{{squadsStatusCode[squad.statusCode]}}</span>
                        </td>
                        <td
                            class="border-t-0 uppercase font-bold px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <span *ngIf="!squad.members || squad.members.length === 0">--</span>
                            <strong *ngIf="squad.members">{{squad.members.length}}</strong>
                        </td>
                        <!-- <td
                            class="cursor-pointer border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                            <a
                                class="text-white bg-indigo-500 text-xs font-bold capitalize px-3 py-1 rounded outline-none ml-2 mr-1 mb-1">
                                Ver Detalhes
                            </a>
                        </td> -->
                    </tr>
                </tbody>
            </table>
            <h2 *ngIf="!squadsList || squadsList.length === 0"
                class="text-blueGray-500 text-center p-8 font-semibold text-xl">
                Nenhum Squad encontrado
            </h2>
        </div>
    </div>
</div>