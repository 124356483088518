<div *ngIf="!onlyAdmin" class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-6/12 px-4">
            <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div class="rounded-t mb-0 px-6 py-6">
                    <h5 class="text-blueGray-500 text-xl uppercase text-center font-bold mt-6">
                        <img src="../../../../assets/img/ginger-cat-mask.png" style="margin: 0 auto" width="300" alt="">
                        Ah Ah Ah you didn't say the magic word!<br />
                        <small style="font-size: 12px;">ADMIN ONLY</small><br />
                        <button (click)="accessCreateAccountToAdmin()"
                            class="bg-blueGray-800 text-white  text-sm font-bold uppercase px-6 py-3 rounded shadow outline-none mr-1 mb-1 mt-5 w-full"
                            type="button">
                            ACESSAR
                        </button>
                    </h5>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="onlyAdmin" class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-6/12 px-4">
            <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div class="rounded-t mb-0 px-6 py-6">
                    <div class="text-center mb-3">
                        <img src="../../../../assets/img/ginger-cat-co-workers.png" style="margin: 0 auto" width="300" class="logo" alt="">
                        <h5 class="text-blueGray-500 text-xl uppercase font-bold mt-6">
                            INDII | Empresas<br />
                            <small style="font-size: 12px">Crie sua conta</small>
                        </h5>
                    </div>
                </div>
                <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form>
                        <h6 class="text-blueGray-500 text-md uppercase mb-3 font-bold">Sobre sua Empresa</h6>
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                CNPJ
                            </label>
                            <input type="text" [imask]="{mask: '00.000.000/0000-00'}" #icnpj
                                (complete)="searchCNPJ(icnpj.value)"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                placeholder="Insira seu CNPJ" />
                        </div>
                        <div class="relative w-full mb-3" *ngIf="companyData">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                <i class="fas fa-check" style="color: green;"></i>&ensp;Empresa
                            </label>
                            <div class="text-xs">
                                <span>
                                    Razão Social: {{companyData.razao_social}}
                                </span><br />
                                <span>
                                    Nome Fantasia: {{companyData.nome_fantasia}}
                                </span><br />
                                <span>
                                    Situação: {{companyData.descricao_situacao_cadastral}}
                                </span>
                            </div>
                        </div>
                        <div *ngIf="companyDataError">
                            <p style="color: tomato;">Ops... CNPJ Inválido!</p>
                        </div>

                        <hr class="mt-6 border-b-1 border-blueGray-300" />

                        <h6 class="text-blueGray-500 text-md uppercase mb-3 font-bold">Sobre você</h6>
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                CPF
                            </label>
                            <input type="text" [imask]="{mask: '000.000.000.00'}" #cpf
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                placeholder="Insira seu CPF" />
                        </div>
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                Nome Completo
                            </label>
                            <input type="text" #fullName
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                placeholder="Insira o seu Nome Completo" />
                        </div>

                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                Telefone
                            </label>
                            <input type="phone" [imask]="{mask: '(00) 00000-0000'}" #phone
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                placeholder="Insira o Telefone" />
                        </div>

                        <hr class="mt-6 border-b-1 border-blueGray-300" />
                        <h6 class="text-blueGray-500 text-md uppercase mb-3 font-bold">Acesso</h6>

                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                Email
                            </label>
                            <input type="email" #email
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                placeholder="Email" />
                        </div>

                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                Senha
                            </label>
                            <input type="password" #password
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                placeholder="Senha" />
                        </div>

                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                Confirmar Senha
                            </label>
                            <input type="password" #confirmPassword
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                placeholder="Senha" />
                        </div>

                        <div class="text-center mt-6">
                            <button
                                (click)="createAccount(fullName.value, cpf.value, phone.value, email.value, password.value, confirmPassword.value)"
                                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                                type="button">
                                Criar Conta
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>