<div *ngIf="loading" class="flex flex-wrap mt-4">
    <div class="w-full h-screen mb-4 px-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
            <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="flex flex-wrap items-center">
                    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                        <h3 class="font-semibold uppercase text-lg text-blueGray-700">
                            Compliance
                        </h3>
                    </div>
                </div>
            </div>
            <div class="block w-full overflow-x-auto">
                <table class="items-center w-full bg-transparent border-collapse">
                    <thead>
                        <tr>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                Contratado<br />
                                <small style="font-size: 8px;">(Informações do contratado)</small>
                            </th>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                ID<br />
                                <small style="font-size: 8px;">(RG, CNH, Passaporte)</small>
                            </th>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                CNPJ<br />
                                <small style="font-size: 8px;">(Cartão de CNPJ, Contrato Social)</small>
                            </th>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr app-documents-list [contract]="contract" *ngFor="let contract of contractList"></tr>
                    </tbody>
                </table>
                <h2 *ngIf="!contractList || contractList.length === 0"
                    class="text-blueGray-500 text-center p-8 font-semibold text-xl">
                    Você ainda não tem nenhum contrato criado
                </h2>
            </div>
        </div>

    </div>
</div>