<div class="container mx-auto px-4 h-full">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-4/12 px-4">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
        <div class="rounded-t mb-0 px-6 py-6">
          <div class="text-center mb-3">
            <h6 class="text-blueGray-500 text-sm font-bold">Entrar em sua conta</h6>
          </div>
          <!-- <div class="btn-wrapper text-center">
            <button
              class="bg-white active:bg-blueGray-50 text-blueGray-700 px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
              type="button">
              <img alt="Google Login" class="w-5 mr-1" src="assets/img/google.svg" />
              Login com o Google
            </button>
          </div>
          <hr class="mt-6 border-b-1 border-blueGray-300" /> -->
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <!-- <div class="text-blueGray-400 text-center mb-3 font-bold">
            <small>Ou login com email e senha</small>
          </div> -->
          <form>
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Email
              </label>
              <input type="email" #email
                (keyup.enter)="signInWithEmail(email.value, pass.value)"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Email" />
            </div>

            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Senha
              </label>
              <input type="password" #pass
                (keyup.enter)="signInWithEmail(email.value, pass.value)"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Senha" />
            </div>

            <div class="text-center mt-6">
              <button (click)="signInWithEmail(email.value, pass.value)"
                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                type="button">
                Entrar
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="flex flex-wrap mt-6 relative">
        <div class="w-1/2">
          <a (click)="passwordModal()" class="text-blueGray-200 cursor-pointer">
            <small>Esqueceu a senha?</small>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>