<div *ngIf="loading" class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
            <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                <h6 class="text-blueGray-700 text-sm font-semibold">
                    <i class="fas fa-users mr-2"></i>Candidatos
                </h6>
            </div>
        </div>
    </div>
    <div class="block w-full overflow-x-auto">

        <h3 *ngIf="!appliesList || appliesList.length === 0" class="text-center p-5">
            <i style="font-size: 80px;" class="fas fa-users text-blueGray-300 mb-3"></i><br/>
            <span class="text-sm text-blueGray-500">Nenhum candidato por aqui ainda :)</span>
        </h3>

        <table *ngIf="appliesList && appliesList.length !== 0" class="items-center w-full bg-transparent border-collapse">
            <thead class="thead-light">
                <tr>
                    <th
                        class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        <strong>{{appliesList.length}}</strong> {{(appliesList.length === 1) ? 'Profissional' : 'Profissionais'}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let apply of appliesList">
                    <th [routerLink]="['/admin/profile', apply.profile.id]"
                        class="border-t-0 cursor-pointer px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                        <img [src]="apply.profile.user.avatar" class="h-12 w-12 bg-white rounded-full border" />
                        <span class="ml-3 font-bold text-blueGray-600">
                            {{apply.profile.user.full_name}}<br/>
                            <small>{{apply.profile.headline}}</small><br/>
                            <small>{{apply.created_at | date : 'dd/MM/yyyy HH:mm'}}</small>
                        </span>
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
</div>