<nav class="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
  <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
    <div class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
      <a [routerLink]="['/']"
        class="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase">
        <img src="../../../../assets/img/indii-w.svg" width="130" alt="">
      </a>
      <button
        class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
        type="button" (click)="setNavbarOpen()">
        <i class="text-white fas fa-bars"></i>
      </button>
    </div>
    <div class="lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none"
      [ngClass]="{ hidden: !navbarOpen, 'block rounded shadow-lg': navbarOpen }" id="example-navbar-warning">
      <ul class="flex flex-col lg:flex-row list-none mr-auto">
        <li class="flex items-center">
          <a href="#for_companies" class="lg:text-white cursor-pointer lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold">
            Para Empresas
          </a>
        </li>
        <li class="flex items-center">
          <a href="#for_contractors" class="lg:text-white cursor-pointer lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold">
            Para Profissionais
          </a>
        </li>
      </ul>

      <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
        <!-- <li *ngIf="!session" class="flex items-center">
          <button
            [routerLink]="['/auth/register']" 
            class="text-white bg-transparent border border-solid text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            type="button">
            Criar Conta
          </button>
        </li> -->
        <li *ngIf="!session" class="flex items-center">
          <button
            [routerLink]="['/auth/login']" 
            class="bg-white text-blueGray-700 active:bg-blueGray-50 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            type="button">
            Fazer Login
          </button>
        </li>

        <li *ngIf="session && user" class="flex items-center">
          <button
            [routerLink]="['/admin/dashboard']" 
            class="text-white bg-transparent border border-solid text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            type="button">
            <i class="fa fa-user-circle mr-2"></i>Olá, {{user.full_name}}
          </button>
        </li>
      </ul>

    </div>
  </div>
</nav>