<app-editable-painel *ngIf="companyData" [editing]="editingSettingCompany" title="Dados da Empresa" (isEditing)="setEditing($event)"
    (onSave)="updateCompany()">
    <app-editable-input-block [value]="companyData.name" title="Nome da Empresa" [editing]="editingSettingCompany">
        <input type="text" [value]="companyData.name" #name (change)="setValue('name', name.value)"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
            placeholder="Insira o nome da empresa" />
    </app-editable-input-block>

    <app-editable-input-block [value]="companyData.email" title="Email da Empresa" [editing]="editingSettingCompany">
        <input type="email" [value]="companyData.email" #email (change)="setValue('email', email.value)"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
            placeholder="Insira o email da empresa" />
    </app-editable-input-block>

    <app-editable-input-block [value]="companyData.cnpj" title="CNPJ" [editing]="editingSettingCompany">
        <input type="text" [value]="companyData.cnpj" #cnpj (change)="setValue('cnpj', cnpj.value)"
            class="disabled border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
            placeholder="Insira o CNPJ" disabled />
        <small *ngIf="editingSettingCompany">*para alterar o CNPJ entre em contato com o suporte!</small>
    </app-editable-input-block>

    <app-editable-input-block [value]="companyData.site" title="Site" [editing]="editingSettingCompany">
        <input type="text" [value]="companyData.site" #site (change)="setValue('site', site.value)"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
            placeholder="Insira o Site da empresa" />
    </app-editable-input-block>

    <hr class="mt-6 border-b-1 border-blueGray-300" />

    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        Endereço
    </h6>

    <app-editable-input-block [value]="companyData.address" title="Endereço da Empresa"
        [editing]="editingSettingCompany">
        <input type="text" [value]="companyData.address" #address (change)="setValue('address', address.value)"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
            placeholder="Insira o endereço da empresa" />
    </app-editable-input-block>

    <app-editable-input-block [value]="companyData.city" title="Cidade" [editing]="editingSettingCompany">
        <input type="text" [value]="companyData.city" #city (change)="setValue('city', city.value)"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
            placeholder="Insira a cidade" />
    </app-editable-input-block>

    <app-editable-input-block [value]="companyData.state" title="Estado" [editing]="editingSettingCompany">
        <input type="text" [value]="companyData.state" #state (change)="setValue('state', state.value)"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
            placeholder="Insira o estado" />
    </app-editable-input-block>

    <hr class="mt-6 border-b-1 border-blueGray-300" />

    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        Sobre a empresa
    </h6>

    <app-editable-input-block [value]="companyData.type" title="Tipo de Empresa" [editing]="editingSettingCompany">
        <ng-select placeholder="Selecione uma opção" [(ngModel)]="selectedCompanyType" (change)="setValue('type', selectedCompanyType)">
            <ng-option *ngFor="let cType of companyTypeOptions" [value]="cType">{{cType}}</ng-option>
        </ng-select>
    </app-editable-input-block>

    <app-editable-input-block [value]="companyData.description" title=" Descrição de sua empresa"
        [editing]="editingSettingCompany">
        <textarea type="text" #description (change)="setValue('description', description.value)"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
            rows="4">{{companyData.description}}</textarea>
    </app-editable-input-block>

</app-editable-painel>