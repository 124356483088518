<div *ngIf="loading" class="flex flex-wrap mt-4" style="min-height: 400px">
	<div class="w-full mb-4 px-4">
		<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
			<div class="rounded-t mb-0 px-4 py-3 border-0">
				<div class="flex flex-wrap items-center">
					<div class="relative w-full px-4 max-w-full flex-grow flex-1">
						<h3 class="font-semibold uppercase text-lg text-blueGray-700">
							Contratos
						</h3>
					</div>
					<div class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
						<!-- <button style="background-color: #ececec;"
                            class="text-blueGray-500 text-xs font-semibold lowercase px-3 py-1 rounded outline-none mr-1 mb-1"
                            type="button">
                            ver Concluidas e Arquivadas
                        </button> -->

						<button [routerLink]="['/admin/novo-contrato']"
							class="text-white bg-indigo-500 text-xs font-bold capitalize px-3 py-1 rounded outline-none ml-2 mr-1 mb-1"
							type="button">
							<i class="fa fa-plus"></i>
							<span class="ml-2">Criar Contrato</span>
						</button>
					</div>
				</div>
			</div>
			<div class="block w-full overflow-x-auto">
				<table class="items-center w-full bg-transparent border-collapse">
					<thead>
						<tr>
							<th
								class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
								Contratado
							</th>
							<th
								class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
								Posição
							</th>
							<th
								class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
								Pagamento
							</th>
							<th
								class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
								Status
							</th>
							<th
								class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
							</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let contract of contractList">
							<td
								class="font-semibold border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
								<img *ngIf="contract.contractor && contract.contractor.avatar"
									[src]="contract.contractor.avatar" class="h-12 w-12 bg-white rounded-full border" />
								<span *ngIf="!contract.contractor || !contract.contractor.avatar"
									class="h-12 w-12 bg-white items-center justify-center flex rounded-full border">
									<i class="fas fa-user text-blueGray-400 text-2xl"></i>
								</span>
								<span class="ml-3" *ngIf="contract.contractor">
									{{contract.contractor.full_name}}<br />
									<small>{{contract.contractor.email}}</small>
								</span>
								<span class="ml-3" *ngIf="!contract.contractor">
									{{contract.contractName}}<br />
									<small style="font-weight: bold;" class="text-orange-500">(Aguardando
										Contratado)</small>
								</span>
							</td>
							<td
								class="font-normal border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
								{{contract.contractorRole}}<br />
								<small>{{contract.seniorityLevel}}</small><br />
								<small>({{contract.contractName}})</small>
							</td>
							<td
								class="font-semibold border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
								<span *ngIf="!isMilestone(contract.contractType)">
									<span *ngIf="!contract.paymentConfig"
										style="opacity: .8; font-weight: 500;">Aguardando
										Configuração</span>
									<span *ngIf="contract.paymentConfig"
										style="font-weight: bold;">{{contract.paymentConfig.rate | currency
										:
										'R$'}}</span><br />
									<strong class="font-semibold text-xs"
										*ngIf="contract.paymentConfig">{{rateTypes[contract.contractType].label}}</strong><br />
									<small class="font-normal" *ngIf="contract.paymentConfig">
										{{paymentCyclesValues[contract.paymentConfig.paymentCycle]}}
									</small>
								</span>
								<span *ngIf="isMilestone(contract.contractType)">
									<app-milestone-table-data [contractId]="contract.id"></app-milestone-table-data>
								</span>
							</td>
							<td
								class="font-semibold border-t-0 text-xs {{contractStatus[contract.status].color}} px-6 align-middle border-l-0 border-r-0 whitespace-nowrap break-words p-4">
								{{contractStatus[contract.status].label}}<br />
								<small>({{contractStatus[contract.status].sub_label}})</small>
							</td>
							<td [routerLink]="['/admin/editar-contrato', contract.id]"
								class="cursor-pointer border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
								<a
									class="text-white bg-indigo-500 text-xs font-bold capitalize px-3 py-1 rounded outline-none ml-2 mr-1 mb-1">
									Acessar
								</a>
							</td>
						</tr>
					</tbody>
				</table>
				<h2 *ngIf="!contractList || contractList.length === 0"
					class="text-blueGray-500 text-center p-8 font-semibold text-xl">
					Você ainda não tem nenhum contrato criado<br />
					<small class="text-sm">
						<a [routerLink]="['/admin/novo-contrato']" style="color: rgb(99, 102, 241);">
							<i class="fa fa-plus"></i>
							<span class="ml-2">Criar Novo Contrato</span>
						</a>
					</small>
				</h2>
			</div>
		</div>

	</div>
</div>