<div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
          <div class="rounded-t mb-0 px-6 py-6">
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-md font-bold">
                  Alterar Senha<br/>
                  <small *ngIf="isLogged">para: {{isLogged.user.email}}</small>
                </h6>
            </div>
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
           
            <form>
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                  Senha
                </label>
                <input type="password" #pass
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow w-full"
                  placeholder="Senha" />
              </div>
  
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                  Confirmar Senha
                </label>
                <input type="password" #confirm
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow w-full"
                  placeholder="Confirmar" />
              </div>
  
              <div class="text-center mt-6">
                <button (click)="changePassword(pass.value, confirm.value)"
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow outline-none mr-1 mb-1 w-full"
                  type="button">
                  Alterar Senha
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>