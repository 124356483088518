<div *ngIf="session && userData">
  <app-sidebar></app-sidebar>
  
  <div class="relative md:ml-64 bg-blueGray-100">
    <app-admin-navbar></app-admin-navbar>

    <app-header-stats-contractor *ngIf="isContractor()"></app-header-stats-contractor>
    <app-header-stats *ngIf="isClient()"></app-header-stats>


    <div class="px-4 md:px-10 mx-auto w-full -m-24" style="padding-bottom: 180px;">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
  </div>
</div>

<div class="items-center justify-center h-screen w-full flex bg-blueGray-100 content-center" *ngIf="!session">
  <div
    class="items-center justify-center mx-auto p-8 flex flex-col bg-white w-1/2 shadow-lg rounded align-baseline content-center">
    <h3 class="text-4xl font-normal leading-normal mt-0 mb-2 text-coolGray-700">
      Sua sessão expirou!
    </h3>
    <button [routerLink]="['/auth/login']" class="mt-3 text-gray-600 bg-transparent border border-solid border-gray-500 hover:bg-gray-500 hover:text-white active:bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
      Ir para o Login
    </button>
  </div>
</div>