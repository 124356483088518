<ul class="list-disc text-blueGray-600 text-xs">
    <li><strong>*Importante:</strong> Todas as nossas oportunidades são para trabalho <strong>REMOTO / HOME OFFICE</strong></li>
    <li><strong>Validação da oportunidade:</strong> após a criação da oportunidade ela deve passar
        pela avaliação do nosso time, o processo é rápido e tem o intuito de potencializar a atração
        de talentos para a oportunidade."</li>
    <li><strong>Título:</strong> é muito importante ser direto(a)! Quanto mais simples melhor, mais
        talentos vão se interessar em sua oportunidade!"</li>
    <li>Tente sempre colocar "Título + Nível de experiência</li>
    <li>Ex: Desenvolvedor(a) React Sênior, UX Designer Pleno, Gestor de RH, Redator Pleno</li>
    <li><strong>Nível de Senioridade:</strong><a class="cursor-pointer ml-1" target="_blank"
            href="https://www.indeed.com/career-advice/career-development/seniority-level">Clique
            aqui para entender mais sobre os níveis de senioridade</a></li>
    <li><strong>Habilidades:</strong> a seleção das habilidades é um dos fatores mais importante
        para o sucesso da sua oportunidade, portanto escolha apenas as habilidades mais relevantes!
    </li>
    <li><strong>Escopo de trabalho:</strong> descrições com poucas informações, podem afastar os
        talentos. <a class="ml-1" target="_blank"
            href="https://research.com/research/scope-of-work-examples">Saiba mais clicando
            aqui!</a></li>
    <li><strong>Tipos de contrato</strong></li>
    <li><strong>Por Hora: </strong> Este formato é utilizado para pagar os profissionais com base
        nas horas trabalhadas naquele período. O cliente assina o contrato e paga a fatura no final
        de uma semana, quinzena ou mês. O valor faturado é gerado com base nas horas totais que o
        profissional ou cliente submete na plataforma. Este tipo de contrato pode ser utilizado para
        freelancers ou contractors / PJs / autônomos.</li>
    <li><strong>Por Entrega/Milestones: </strong> Este é um contrato "único" onde o cliente divide o
        projeto em entregas/milestones e atribui um valor a ser pago a cada uma das entregas. A
        fatura é gerada com base no trabalho aprovado (entregas). Este formato pode ser utilizado
        para projetos de curto prazo de qualquer escopo.</li>
    <li><strong>Fixo: </strong> Este é um contrato contínuo usado para pagar aos profissionais uma
        quantia fixa definida semanalmente, quinzenalmente ou mensalmente. É ideal para projetos de
        longo prazo.</li>
    <!-- <li><strong>Indii Squad:</strong> A forma mais fácil, transparente e segura de contratar profissionais PJ,
        ideal para projetos de longo prazo.<br/>
        Entre em contato para saber mais: <br/>
        <b>contato@indii.com.br</b>
    </li> -->
</ul>