<!-- Navbar -->
<nav *ngIf="user"
  class="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
  <div class="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
    <!-- Brand -->
    <a class="text-white text-sm uppercase hidden lg:inline-block font-semibold">
      <span *ngIf="isClient()">INDII &#xb7; EMPRESAS</span>
      <span *ngIf="isContractor()">INDII &#xb7; PROFISSIONAIS</span>
    </a>
    <!-- User -->
    <ul class="flex-col md:flex-row list-none items-center hidden md:flex">
      <app-user-dropdown [user]="user" class="block"></app-user-dropdown>
    </ul>
  </div>
</nav>