<div class="flex flex-wrap">
    <div class="w-full lg:w-8/12 px-4">
        <app-editable-painel [hideEdit]="hideEdit" [editing]="editingNewJob" [title]="titlePanel" (isEditing)="setEditing($event)"
            (onSave)="triggerSaveOrEdit()" hideCancel="true">

            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                INFORMAÇÕES PRINCIPAIS
            </h6>

            <app-editable-input-block [value]="jobData?.title" title="Título da Oportunidade" [editing]="editingNewJob">
                <input type="text" [value]="jobData?.title" #title (change)="setValue('title', title.value)"
                    class="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
                    placeholder="(ex: Desenvolvedor Web, Designer, Gestor de RH, etc)" />
            </app-editable-input-block>

            <app-editable-input-block [value]="jobData?.seniorityLevel" title="Nível de Senioridade"
                [editing]="editingNewJob">
                <ng-select placeholder="Selecione uma opção" [(ngModel)]="selectedSeniorityLevel"
                    (change)="setValue('seniorityLevel', selectedSeniorityLevel)">
                    <ng-option *ngFor="let sLevel of seniorityLevelList" [value]="sLevel">{{sLevel}}</ng-option>
                </ng-select>
            </app-editable-input-block>

            <app-editable-input-block isList="true" [value]="jobData?.skills" title="Habilidades Obrigatórias"
                [editing]="editingNewJob">
                <ng-select placeholder="Insira a habilidade e pressione [enter] para adicionar"
                    [(ngModel)]="selectedSkillsList" [items]="[]" [addTag]="true" [multiple]="true" [selectOnTab]="true"
                    [isOpen]="false" (change)="setValue('skills', selectedSkillsList)">
                </ng-select>
            </app-editable-input-block>

            <hr class="mt-6 border-b-1 border-blueGray-300" />

            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                SOBRE A VAGA
            </h6>

            <app-editable-input-block [value]="jobData?.scopeOfWork"
                title="Escopo de trabalho e descrição de atividades" [editing]="editingNewJob">
                <textarea type="text" #scopeOfWork (change)="setValue('scopeOfWork', scopeOfWork.value)"
                    class="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
                    placeholder="Quais a principais atividades e responsabilidades para esta oportunidade?"
                    rows="4">{{jobData?.scopeOfWork}}</textarea>
            </app-editable-input-block>

            <app-editable-input-block [value]="jobData?.requirements" title="Requisitos mínimos"
                [editing]="editingNewJob">
                <textarea type="text" #requirements (change)="setValue('requirements', requirements.value)"
                    class="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
                    placeholder="O que o profissional precisa para concorrer a esta oportunidade?"
                    rows="4">{{jobData?.requirements}}</textarea>
            </app-editable-input-block>

            <hr class="mt-6 border-b-1 border-blueGray-300" />

            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                ESPECIFICAÇÕES
            </h6>

            <app-editable-input-block [value]="jobData?.profileType" title="Tipo de Profissional"
                [editing]="editingNewJob">
                <ng-select placeholder="Selecione uma opção" [(ngModel)]="selectedProfileType"
                    (change)="setValue('profileType', selectedProfileType)">
                    <ng-option *ngFor="let pType of profileType" [value]="pType">{{pType}}</ng-option>
                </ng-select>
            </app-editable-input-block>

            <app-editable-input-block [value]="jobData?.contractType" title="Tipo de Contrato"
                [editing]="editingNewJob">
                <ng-select placeholder="Selecione uma opção" [(ngModel)]="selectedContractType"
                    (change)="setValue('contractType', selectedContractType)">
                    <ng-option *ngFor="let cType of contractType" [value]="cType">{{cType}}</ng-option>
                </ng-select>
            </app-editable-input-block>

            <app-editable-input-block isCurrency="true" [value]="jobData?.rate" title="Valor do Contrato"
            [editing]="editingNewJob">
            <div class="mt-4" *ngIf="selectedContractType">
                <input *ngIf="!rateToCheck" type="text" [(ngModel)]="selectedRate" currencyMask
                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                    class="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-white rounded text-lg shadow focus:outline-none focus:ring w-full ease-linear"
                    placeholder="Insira o valor (R$)" />

                <h6 *ngIf="rateToCheck" class="text-blueGray-600 text-sm mt-3 mb-6 font-bold capitalize">
                    Combinar com contratado
                </h6>
            </div>

            <h6 *ngIf="!selectedContractType" class="text-blueGray-400 text-sm mt-3 mb-6 font-bold lowercase">
                -- Selecione o tipo de contrato --
            </h6>
        </app-editable-input-block>

            <app-editable-input-block [value]="jobData?.availabilityType" title="Disponibilidade"
                [editing]="editingNewJob">
                <ng-select placeholder="Selecione uma opção" [(ngModel)]="selectedAvailabilityType"
                    (change)="setValue('availabilityType', selectedAvailabilityType)">
                    <ng-option *ngFor="let aType of availabilityType" [value]="aType">{{aType}}</ng-option>
                </ng-select>
            </app-editable-input-block>

            <!-- <app-editable-input-block [value]="jobData?.meetingTimeType" title="Horários para Reuniões"
                [editing]="editingNewJob">
                <ng-select placeholder="Selecione uma opção" [(ngModel)]="selectedMeetingTimeType"
                    (change)="setValue('meetingTimeType', selectedMeetingTimeType)">
                    <ng-option *ngFor="let mType of meetingTimeType" [value]="mType">{{mType}}</ng-option>
                </ng-select>
            </app-editable-input-block> -->

        </app-editable-painel>
    </div>
    <div class="w-full lg:w-4/12 px-4">
        <div *ngIf="!editingNewJob"
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-50 border-0">
            <div class="flex-auto px-4 pb-2 lg:px-10 py-10 pt-8">
                <div class="flex flex-wrap pb-4">
                    <ul class="w-full list-disc text-blueGray-600 text-xs">
                        <li><strong>Criado em:</strong> {{jobData.created_at | date : 'dd/MM/yyyy' }}</li>
                        <li><strong>Status:</strong> <span
                                class="{{jobStatusList[jobData.statusCode].color}} text-sm font-semibold ml-2">{{jobStatusList[jobData.statusCode].label}}</span>
                        </li>
                        <li *ngIf="jobData.statusCode !== 400 " class="mt-5">
                            <button (click)="closeJob(jobData.id)" style="background-color: tomato;"
                                class="text-white w-full text-xs font-bold px-3 py-1 rounded outline-none mr-1 mb-1"
                                type="button">
                                <i class="fas fa-archive mr-2"></i>
                                Fechar Oportunidade
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div *ngIf="editingNewJob"
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-50 border-0">
            <div class="rounded-t bg-white mb-0 px-6 py-6 border-0">
                <div class="flex flex-wrap items-center">
                    <div class="relative w-full px-2 max-w-full flex-grow flex-1">
                        <h6 class="text-blueGray-700 text-sm font-semibold">
                            <i class="fas fa-graduation-cap mr-2"></i>Dicas
                        </h6>
                    </div>
                </div>
            </div>
            <div class="flex-auto px-4 pb-2 lg:px-10 py-10 pt-8">
                <div class="flex flex-wrap pb-4">
                    <app-tips-job></app-tips-job>
                </div>
            </div>
        </div>

        <app-profile-applied *ngIf="!editingNewJob && jobData" [jobId]="jobData.id"></app-profile-applied>
    </div>
</div>