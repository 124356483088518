<div *ngIf="showForm()" class="flex flex-wrap">
  <div class="w-full lg:w-8/12 px-4">
    <!-- BASIC INFO | CONTRACT -->
    <app-editable-painel [hideEdit]="hideEdit" [editing]="editingNewContract" [title]="getTitlePanel()" (isEditing)="setEditing($event)"
      (onSave)="triggerSaveOrEdit()" [hideCancel]="hideCancel">

      <section *ngIf="editingNewContract && !contractData?.id" class="w-full mb-8">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          OPÇÕES DE CONTRATO
        </h6>

        <div id="selectContractOption" class="w-full flex flex-wrap mb-6">
          <div class="w-full lg:w-6/12 px-4">
            <div
              class="disabled relative text-center m-aut border-coolGray-600 py-6 cursor-pointer shadow-lg border-2 w-full h-96 text-blueGray-600 rounded-lg selectBoxType">
              <h6 class="font-semibold mb-3 pt-6">Template de Contrato</h6>
              <p class="text-xs leading-4 px-6">Use o modelo de contrato seguro e revisado profissionalmente da INDII.
              </p>
              <span class="soon">Em Breve</span>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div
              class="text-center relative m-aut border-coolGray-600 py-6 cursor-pointer shadow-lg border-2 w-full h-96 text-blueGray-600 rounded-lg selectBoxType">
              <h6 class="font-semibold mb-3 pt-6">Contrato Próprio</h6>
              <p class="text-xs leading-4 px-6">Utilize seu próprio contrato assinado, fazendo o upload dele pela
                plataforma.</p>
              <span class="selected-label text-white"><i class="fas fa-check mr-1"></i> Selecionado</span>
            </div>
          </div>
        </div>
        <p class="py-2 w-full text-xs">
          *Importante: Certifique-se que o contrato carregado na plataforma seja valido juridicamente, esteja assinado
          corretamente por todas as partes. <span class="font-semibold"> A INDII não se responsabiliza por quaisquer
            problemas jurídicos entre contratado e contratante.</span>
        </p>
      </section>

      <h6 class="text-blueGray-400 text-sm font-bold uppercase"
        *ngIf="!editingNewContract && contractData && contractData?.id">
        Configurações do Contrato
      </h6>

      <section class="w-full p-3" *ngIf="!editingNewContract && contractData && contractData?.id">
        <ul>
          <li class="font-normal">
            <span class="text-blueGray-600 uppercase text-xs">Ciclo de pagamento</span> <br />
            <small class="font-semibold mt-1 text-sm">{{rateType[contractData.contractType].label}}</small>
          </li>

          <li class="mt-3 font-normal">
            <span class="text-blueGray-600 uppercase text-xs">Tipo de Contrato</span> <br />
            <small class="font-semibold mt-1 text-sm">{{contractModelType[contractData.contractModel].label}}</small>
          </li>

          <li class="mt-3 font-normal">
            <span class="text-blueGray-600 uppercase text-xs">Criado em</span> <br />
            <small class="font-semibold mt-1 text-sm">{{contractData.created_at | date: 'dd/MM/yyyy'}}</small>
          </li>
        </ul>
      </section>

      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        INFORMAÇÕES DO CONTRATO
      </h6>

      <app-editable-input-block [value]="contractData?.contractName" title="Nome do Projeto ou Time"
        [editing]="editingNewContract">
        <input type="text" [value]="contractData?.contractName" #contractName
          (change)="setValue('contractName', contractName.value)"
          class="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
          placeholder="Ex: Landing Page, Consultoria de Marketing, Aplicativo MVP, Time de TI" />
      </app-editable-input-block>

      <app-editable-input-block [value]="contractData?.contractorRole" title="Cargo do profissional"
        [editing]="editingNewContract">
        <input type="text" [value]="contractData?.contractorRole" #contractorRole
          (change)="setValue('contractorRole', contractorRole.value)"
          class="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
          placeholder="Ex: Full-stack Developer, Designer, Programador(a), Copywriter, etc" />
      </app-editable-input-block>

      <app-editable-input-block [value]="contractData?.seniorityLevel" title="Nível de Senioridade do profissional"
        [editing]="editingNewContract">
        <ng-select placeholder="Selecione uma opção" [(ngModel)]="selectedSeniorityLevel"
          (change)="setValue('seniorityLevel', selectedSeniorityLevel)">
          <ng-option *ngFor="let sLevel of seniorityLevelList" [value]="sLevel">{{sLevel}}</ng-option>
        </ng-select>
      </app-editable-input-block>

      <app-editable-input-block isDate="true" [value]="contractData?.startAt" title="Data de início do contrato"
        [editing]="editingNewContract">
        <input type="date" [value]="contractData?.startAt" #startAt (change)="setValue('startAt', startAt.value)"
          class="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear" />
      </app-editable-input-block>

      <a (click)="setEndDate()" *ngIf="!contractData?.endAt"
        class="text-indigo-600 mb-6 bg-transparent border border-solid border-indigo-custom hover:text-white font-bold uppercase text-xs px-4 py-2 rounded outline-none">Configurar
        Data de Termino do contrato</a>

      <app-editable-input-block *ngIf="hasEndDate || contractData?.endAt" isDate="true" [value]="contractData?.endAt"
        title="Data de término do contrato" [editing]="editingNewContract" class="mt-6">
        <input type="date" [value]="contractData?.endAt" #endAt (change)="setValue('endAt', endAt.value)"
          class="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear" />
      </app-editable-input-block>
    </app-editable-painel>

    <!-- PAYMENT INFO | CONTRACT -->
    <app-payment-block [hideEdit]="hideEdit" *ngIf="showPaymentBlock()" [contractData]="contractData"></app-payment-block>

    <section *ngIf="showMilestoneBlock()">
      <h6 class="text-blueGray-500 text-lg mt-3 mb-6 font-normal uppercase milestone-header">
        <span>
          <i class="fas fa-tasks"></i>&ensp;Entregas
        </span>

        <button (click)="addNewMilestone()"
          class="text-white bg-indigo-500 text-xs font-bold capitalize px-3 py-1 rounded outline-none ml-2 mr-1 mb-1"
          type="button">
          <i class="fa fa-plus"></i>
          <span class="ml-2">Criar nova Entrega/Milestone</span>
        </button>
      </h6>

      <app-milestone-block (onSaveMilestone)="onSaveMilestone()" [contractData]="contractData"
        [milestoneData]="milestone" *ngFor="let milestone of milestonesList"></app-milestone-block>
    </section>

    <div *ngIf="isOpen() && !editingNewContract && contractData && contractData?.id" style="border: 2px solid tomato"
      class="relative flex flex-col min-w-0 break-words w-full mb-6 py-6 px-6 shadow-lg rounded bg-white">
      <p style="color: tomato" class="block uppercase text-xs font-normal">
        <strong>Danger Zone:</strong><br />
        <a (click)="archiveContract(contractData.id)"
          class="danger-zone-btn bg-transparent inline-flex border border-solid font-bold uppercase text-xs px-4 py-2 mt-4 rounded outline-none ease-linear">
          Concluir e Arquivar Contrato
        </a>
      </p>
    </div>
  </div>

  <div class="w-full lg:w-4/12 px-4">
    <!-- INFO BLOCK -->
    <app-info-contract [contractData]="contractData"></app-info-contract>

    <!-- CONTRACTOR AND INVITE | CONTRACT -->
    <app-contractor-block *ngIf="showContractorBlock()" [contractData]="contractData"></app-contractor-block>

    <!-- CONTRACT BLOCK UPLOAD | CONTRACT -->
    <app-upload-contract-block *ngIf="showImportContractBlock()" [contractData]="contractData">
    </app-upload-contract-block>
  </div>
</div>

<section *ngIf="!selectedContractType && editingNewContract" class="h-screen">
  <app-select-type-contract (selectedTypeContract)="setSelectedContractType($event)">
  </app-select-type-contract>
</section>