<a class="text-blueGray-500 block" href="#" (click)="toggleDropdown($event)" #btnDropdownRef>
  <div class="items-center flex">
    <span
      class="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full overflow-hidden">
      <div *ngIf="user.userType !== 'CLIENT' && user.avatar" [ngStyle]="{'background-image': 'url(' + user.avatar + ')'}"
        class="avatar-img-l shadow-xl rounded-full border-none mb-36"></div>

      <app-avatar-default *ngIf="!user.avatar || user.userType === 'CLIENT'" [seed]="user.email"></app-avatar-default>
    </span>
  </div>
</a>

<div class="min-w-48 z-50" #popoverDropdownRef>
  <div class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-48"
    [ngClass]="dropdownPopoverShow ? 'block' : 'hidden'">
    <span class="text-sm py-2 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-700">
      {{user.full_name}}<br />
      <small *ngIf="user.userType === 'CLIENT'">Empresa</small>
      <small *ngIf="user.userType !== 'CLIENT'">Profissional</small>
    </span>
    <a [routerLink]="['/admin/settings']"
      class="text-sm py-2 cursor-pointer px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
      Configurações
    </a>
    <a [routerLink]="['/auth/alterar-senha']" class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
      Alterar Senha
    </a>
    <a (click)="openHelpBlock()"
      class="cursor-pointer text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
      Ajuda
    </a>
    <div class="h-0 my-2 border border-solid border-blueGray-100"></div>
    <a (click)="logout()"
      class="cursor-pointer text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
      Sair
    </a>
  </div>
</div>