<div *ngIf="loaded" class="flex flex-wrap">
    <div class="w-full px-4">
        <app-editable-painel [editing]="editingProfile" title="Meu Perfil" [hideCancel]="setHideCancel()"
            (isEditing)="setEditing($event)" (onSave)="updateOrSaveProfile()">

            <div class="w-full flex flex-wrap flex-row edit-img-block">
                <div class="flex">
                    <div [ngStyle]="{'background-image': 'url(' + getAvatarImg() + ')'}"
                        class="avatar-img shadow-xl rounded-full border-none mb-36"></div>
                </div>

                <div class="flex self-center">
                    <input (change)="uploadedAvatar($event)" id="upload" type="file">

                    <label for="upload">
                        <span class="uppercase"><i class="fas fa-camera"></i>&emsp;Alterar foto</span>
                    </label>
                </div>
            </div>

            <app-editable-input-block [value]="profileData?.linkedin" title="Linkedin" [editing]="editingProfile">
                <input type="text" [value]="profileData?.linkedin" #linkedin
                    (change)="setValue('linkedin', linkedin?.value)"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
                    placeholder="Insira o link do seu Linkedin" />
            </app-editable-input-block>

            <app-editable-input-block [value]="profileData?.headline" title="Área de atuação / Headline"
                [editing]="editingProfile">
                <input type="text" [value]="profileData?.headline" #headline
                    (change)="setValue('headline', headline?.value)"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
                    placeholder="Insira o nome da empresa" />
            </app-editable-input-block>

            <app-editable-input-block [value]="profileData?.seniorityLevel" title="Nível de Senioridade"
                [editing]="editingProfile">
                <ng-select placeholder="Selecione uma opção" [(ngModel)]="selectedSeniorityLevel"
                    (change)="setValue('seniorityLevel', selectedSeniorityLevel)">
                    <ng-option *ngFor="let sLevel of seniorityLevelList" [value]="sLevel">{{sLevel}}</ng-option>
                </ng-select>
            </app-editable-input-block>

            <app-editable-input-block [value]="profileData?.availability" title="Disponibilidade"
                [editing]="editingProfile">
                <ng-select placeholder="Selecione uma opção" [(ngModel)]="selectedAvailabilityType"
                    (change)="setValue('availability', selectedAvailabilityType)">
                    <ng-option *ngFor="let aType of availabilityType" [value]="aType">{{aType}}</ng-option>
                </ng-select>
            </app-editable-input-block>

            <app-editable-input-block [value]="profileData?.bio" title="Sobre você" [editing]="editingProfile">
                <p style="font-size: 10px;">Fale um pouco sobre você</p>
                <textarea type="text" #bio (change)="setValue('bio', bio.value)"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
                    rows="4">{{profileData.bio}}</textarea>
            </app-editable-input-block>

            <app-editable-input-block isList="true" [value]="profileData?.skills" title="Habilidades principais"
                [editing]="editingProfile">
                <ng-select placeholder="Insira a habilidade e pressione [enter] para adicionar"
                    [(ngModel)]="selectedSkillsList" [items]="[]" [addTag]="true" [multiple]="true" [selectOnTab]="true"
                    [isOpen]="false" (change)="setValue('skills', selectedSkillsList)">
                </ng-select>
            </app-editable-input-block>

            <app-editable-input-block [value]="rateTypeLabels[profileData?.typeRate]?.label"
                title="Você prefere cobrar um valor por Hora ou Fixo?" [editing]="editingProfile">
                <ng-select placeholder="Selecione uma opção" [(ngModel)]="selectedRateType"
                    (change)="setValue('typeRate', selectedRateType)">
                    <ng-option *ngFor="let rType of rateTypeList" [value]="rType">{{rateTypeLabels[rType].label}}
                    </ng-option>
                </ng-select>
            </app-editable-input-block>

            <app-editable-input-block *ngIf="selectedRateType" isCurrency="true" [value]="profileData?.minRate"
                title="Qual o valor MÍNIMO que você costuma cobrar?" [editing]="editingProfile">
                <input type="text" [(ngModel)]="selectedMinRate" currencyMask
                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                    class="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-white rounded text-lg shadow focus:outline-none focus:ring w-full ease-linear"
                    placeholder="Insira o valor (R$)" />

                <small class="text-blueGray-600 text-xs">*Insira o valor
                    <strong>{{rateTypeLabels[selectedRateType].label}}</strong> </small>
            </app-editable-input-block>

            <app-editable-input-block *ngIf="selectedRateType" isCurrency="true" [value]="profileData?.maxRate"
                title="Qual o valor MÁXIMO que você costuma cobrar?" [editing]="editingProfile">
                <input type="text" [(ngModel)]="selectedMaxRate" currencyMask
                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                    class="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-white rounded text-lg shadow focus:outline-none focus:ring w-full ease-linear"
                    placeholder="Insira o valor (R$)" />

                <small class="text-blueGray-600 text-xs">*Insira o valor
                    <strong>{{rateTypeLabels[selectedRateType].label}}</strong> </small>
            </app-editable-input-block>

            <hr class="w-full mt-6 border-b-1 border-blueGray-300" />

            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold">
                LINKS<br />
                <small>Insira links relevantes para seu perfil como Portfolio, Site pessoal, Github, Dribbble,
                    Pinterest, etc...</small>
            </h6>

            <ul class="w-full mx-6 mb-8 text-md text-blueGray-700">
                <li class="mb-2 w-full" *ngFor="let link of profileLinks, let i = index">
                    <i class="fas fa-link mr-2 text-xs"></i>
                    {{link}}
                    <span *ngIf="editingProfile" (click)="removeLinkFromListToSave(i)"
                        class="text-red-500 text-xs lowercase ml-2 cursor-pointer">Remover
                        link</span>
                </li>
                <li style="padding-top: 20px">
                    <a *ngIf="editingProfile" (click)="addLink()"
                        class="text-indigo-600 cursor-pointer mb-6 bg-transparent border border-solid border-indigo-custom hover:text-white font-bold uppercase text-xs px-4 py-2 rounded outline-none">
                        <i class="fas fa-plus mr-1"></i>Adicionar um link
                    </a>
                </li>
            </ul>

        </app-editable-painel>
    </div>

    <!-- <div class="w-full lg:w-4/12 px-4">
        <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-50 border-0">
            <div class="flex-auto px-4 pb-2 lg:px-10 py-10 pt-8">
                <div class="flex flex-wrap pb-4">
                    <ul class="w-full list-disc text-blueGray-600">
                        <li class="text-sm font-semibold">Projetos & Experiências Profissionais</li>
                        <li class="mt-5">
                            <a (click)="editOrCreateWorkExpModal()"
                                class="w-full block text-center text-indigo-600 cursor-pointer mb-6 bg-transparent border border-solid border-indigo-custom hover:text-white font-bold uppercase text-xs px-4 py-2 rounded outline-none">
                                <i class="fas fa-plus mr-2"></i>Adicionar
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-50 border-0">
            <div class="flex-auto px-4 pb-2 lg:px-10 py-10 pt-8">
                <div class="flex flex-wrap pb-4">
                    <ul *ngFor="let workEx of workingExperienceList; let i = index"
                        class="w-full list-disc text-sm mb-5 text-blueGray-600">
                        <li class="font-semibold">{{workEx.title}}</li>
                        <li class="mt-2">{{workEx.company}}</li>
                        <li class="text-left mt-2 text-xs block whitespace-pre-line">{{workEx.description}}</li>
                        <li class="mt-3">De {{workEx.startedAt | date: 'dd/MM/yyy'}}</li>
                        <li class="mt-1" *ngIf="workEx.stillWorking">Até Hoje</li>
                        <li class="mt-1" *ngIf="!workEx.stillWorking">Até {{workEx.endAt | date: 'dd/MM/yyyy'}}</li>
                        <li (click)="deleteWorkExp(workEx.id)" class="text-red-500 mt-2 cursor-pointer">Deletar</li>
                        <li (click)="editOrCreateWorkExpModal(workEx)" class="text-teal-500 mt-2 cursor-pointer">Editar</li>
                        <li *ngIf="i !== (workingExperienceList.length - 1)" class="w-full mt-4 border-b-1"
                            style="border: 1px solid #e1e7ee;"></li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->
</div>