<div *ngIf="loading" class="flex flex-wrap mt-4">
    <div class="w-full h-screen mb-4 px-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
            <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="flex flex-wrap items-center">
                    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                        <h3 class="font-semibold uppercase text-lg text-blueGray-700">
                            Oportunidades
                        </h3>
                    </div>
                    <div class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                        <!-- <button style="background-color: #ececec;"
                            class="text-blueGray-500 text-xs font-semibold lowercase px-3 py-1 rounded outline-none mr-1 mb-1"
                            type="button">
                            ver Concluidas e Arquivadas
                        </button> -->

                        <button [routerLink]="['/admin/nova-oportunidade']"
                            class="text-white bg-indigo-500 text-xs font-bold capitalize px-3 py-1 rounded outline-none ml-2 mr-1 mb-1"
                            type="button">
                            <i class="fa fa-plus"></i>
                            <span class="ml-2">Criar Oportunidade</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="block w-full overflow-x-auto">
                <table class="items-center w-full bg-transparent border-collapse">
                    <thead>
                        <tr>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                Título
                            </th>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                Valor
                            </th>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                Status
                            </th>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                Tipo
                            </th>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                Criado em
                            </th>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                Interessados
                            </th>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let job of jobsList">
                            <td [routerLink]="['/admin/editar-oportunidade', job.id]"
                                class="cursor-pointer border-t-0 font-bold px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {{job.title}}<br/>
                                <small>{{job.seniorityLevel}}</small>
                            </td>
                            <td
                                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {{job.rate | currency : 'R$'}} <br/>
                                <small>{{job.contractType}}</small>
                            </td>
                            <td
                                class=" {{jobStatusList[job.statusCode].color}} font-semibold border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {{jobStatusList[job.statusCode].label}}
                            </td>
                            <td
                                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {{job.profileType}}<br />
                                <small class="lowercase">({{job.availabilityType}})</small>
                            </td>
                            <td
                                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {{job.created_at | date : 'dd/MM/yyyy'}}
                            </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                <div class="flex" [innerHTML]="getCountApplies(job.id, appliesListByCompany)"></div>
                            </td>
                            <td
                                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                                <a  [routerLink]="['/admin/editar-oportunidade', job.id]"
                                    class="text-white bg-indigo-500 text-xs font-bold capitalize px-3 py-1 rounded outline-none ml-2 mr-1 mb-1">
                                    Acessar
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h2 *ngIf="!jobsList || jobsList.length === 0"
                    class="text-blueGray-500 text-center p-8 font-semibold text-xl">
                    Você não tem nenhuma Oportunidade criada<br />
                    <small class="text-sm">
                        <a [routerLink]="['/admin/nova-oportunidade']" style="color: rgb(99, 102, 241);">
                            <i class="fa fa-plus"></i>
                            <span class="ml-2">Criar Nova Oportunidade</span>
                        </a>
                    </small>
                </h2>
            </div>
        </div>

    </div>
</div>