<div *ngIf="loading" class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="rounded-t mb-0 px-2 py-3 border-0">
        <div class="flex flex-wrap items-center">
            <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                <h6 class="text-blueGray-700 uppercase text-xs font-semibold">
                    Candidatos
                </h6>
            </div>
        </div>
    </div>
    <div class="block w-full overflow-x-auto">

        <h3 *ngIf="!appliesList || appliesList.length === 0" class="text-center p-5">
            <img src="../../../../assets/img/ginger-cat-no-comments.png" width="250" style="margin: 0 auto; opacity: .9;" alt="Candidaturas">
            <span class="text-sm text-blueGray-500">Nenhum candidato por aqui ainda :)</span><br />
            <a [routerLink]="['/admin/nova-oportunidade']"
                class="text-indigo-600 text-xs font-bold capitalize px-3 py-1 rounded outline-none ml-2 mr-1 mb-1">
                <i class="fa fa-plus"></i>
                <span class="ml-1">Criar Oportunidade</span>
            </a>
        </h3>

        <table *ngIf="appliesList && appliesList.length !== 0"
            class="items-center w-full bg-transparent border-collapse">
            <thead class="thead-light">
                <tr>
                    <th
                        class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        <strong>{{appliesSize}}</strong> Candidatos Recentes
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let apply of appliesList">
                    <th [routerLink]="['/admin/profile', apply.profile.id]"
                        class="border-t-0 cursor-pointer px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center" style="font-size: 11px;">
                        <img [src]="apply.profile.user.avatar" class="h-12 w-12 bg-white rounded-full border" />
                        <span class="ml-3 font-semibold text-blueGray-600">
                            {{apply.profile.user.full_name}}<br />
                            <small class="font-normal">{{apply.profile.headline}}</small><br />
                            <small class="font-normal">{{apply.created_at | date : 'dd/MM/yyyy'}}</small><br/>
                            <small style="font-size: 9px;" class="font-normal">Op. {{apply.job.id}}: {{apply.job.title}}</small><br />
                        </span>
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
</div>