<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-50 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6 border-0">
        <div class="flex flex-wrap items-center">
            <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                <h6 class="text-blueGray-700 text-md uppercase font-semibold">{{title}}</h6>
            </div>
            <div *ngIf="!hideEdit" class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                <button *ngIf="!editing" (click)="toggleEditing()"
                    class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button">
                    Editar
                </button>
            </div>
        </div>
    </div>
    <div class="flex-auto px-4 pb-2 lg:px-10 py-10 pt-8">
        <div class="flex flex-wrap">
            <ng-content></ng-content>
        </div>
    </div>
    <div *ngIf="editing" class="text-right mt-4 pb-4 pr-4 pt-6 save-block">
        <button *ngIf="!hideCancel" (click)="toggleEditing()"
            class="cancel-btn text-xs font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
            type="button">
            Cancelar
        </button>
        <button style="margin-left: 30px;"
            (click)="saveEmitter()"
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button">
            Salvar
        </button>
    </div>
</div>