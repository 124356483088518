<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-50 border-0">
    <div class="flex-auto px-1 py-2">
        <div class="flex flex-wrap pb-4">
            <h6 class="w-full text-blueGray-400 text-center text-sm mt-3 mb-6 font-bold uppercase">
                <span *ngIf="!contractData.contractSelfUploaded">Carregar Contrato</span>
                <span *ngIf="contractData.contractSelfUploaded">Contrato de Prestação de Serviço</span>
            </h6>
            <div class="w-full text-center">
                <div *ngIf="!contractData.contractSelfUploaded" class="flex justify-center">
                    <input (change)="uploadedFile($event)" id="upload" type="file">

                    <label for="upload">
                        <i class="fas fa-file-upload text-4xl my-4"></i>
                        <br/>
                        <span class="uppercase">Fazer Upload do contrato</span>
                    </label>
                </div>

                <div *ngIf="contractData.contractSelfUploaded" class="flex justify-center">
                    <a (click)="downloadContract()"
                    class="text-indigo-600 w-full mb-6 bg-transparent border border-solid border-indigo-custom font-bold uppercase text-xs px-4 py-2 rounded outline-none">
                    <i class="fas fa-cloud-download-alt mr-2"></i> Baixar Contrato
                </a>
                </div>
            </div>
        </div>
    </div>
</div>