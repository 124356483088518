<div *ngIf="loading" class="flex flex-wrap mt-4">
    <div class="w-full h-screen mb-4 px-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
            <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="flex flex-wrap items-center">
                    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                        <h3 class="font-semibold uppercase text-lg text-blueGray-700">
                            Meus Contratos
                        </h3>
                    </div>
                </div>
            </div>
            <div class="block w-full overflow-x-auto">
                <table class="items-center w-full bg-transparent border-collapse">
                    <thead>
                        <tr>
                            <th style="padding-left: 30px"
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                Empresa
                            </th>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                Posição
                            </th>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                Pagamento
                            </th>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                Status
                            </th>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let contract of myContractsList">
                            <td
                                class="font-semibold border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                <span class="h-12 w-12 bg-white items-center justify-center flex rounded-full border">
                                    <i class="fas fa-building text-blueGray-400 text-2xl"></i>
                                </span>
                                <span class="ml-3">
                                    {{contract.company.name}}<br />
                                    <small>{{contract.contractName}}</small><br />
                                </span>
                            </td>
                            <td
                                class="font-normal border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {{contract.contractorRole}}<br />
                                <small>{{contract.seniorityLevel}}</small><br />
                            </td>
                            <td
                                class="font-semibold border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                <span *ngIf="!isMilestone(contract.contractType)">
                                    <small *ngIf="!contract.paymentConfig"
                                        style="opacity: .7; font-weight: 400;">(Aguardando Configuração)</small>
                                    <span *ngIf="contract.paymentConfig"
                                        style="font-weight: bold;">{{contract.paymentConfig.rate | currency :
                                        'R$'}}</span><br />
                                    <span class="font-normal">{{rateTypes[contract.contractType].label}}</span><br />
                                    <small class="font-normal" *ngIf="contract.paymentConfig">
                                        {{paymentCyclesValues[contract.paymentConfig.paymentCycle]}}
                                    </small>
                                </span>
                                <span *ngIf="isMilestone(contract.contractType)">
                                    <app-milestone-table-data [contractId]="contract.id"></app-milestone-table-data>
                                </span>
                            </td>
                            <td
                                class="font-semibold border-t-0 text-sm {{contractStatus[contract.status].color}} px-6 align-middle border-l-0 border-r-0 whitespace-nowrap break-words p-4">
                                {{contractStatus[contract.status].label}}<br />
                                <small>({{contractStatus[contract.status].sub_label}})</small>
                            </td>
                            <td
                                class="cursor-pointer border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                                <a [routerLink]="['/admin/m/contrato', contract.id]"
                                    class="text-white bg-indigo-500 text-xs font-bold capitalize px-3 py-1 rounded outline-none ml-2 mr-1 mb-1">
                                    Acessar
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h2 *ngIf="!myContractsList || myContractsList.length === 0"
                    class="text-blueGray-500 text-center p-8 font-semibold text-xl">
                    Você não tem nenhum contrato ativo
                </h2>
            </div>
        </div>

    </div>
</div>