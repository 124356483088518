<section *ngIf="profileData">
    <section style="height: 350px;" class="relative block">
        <div class="absolute top-0 w-full h-full bg-center bg-cover rounded-lg overflow-hidden" style="
            background-image: url('https://ppcjgvkwowexttcrodgv.supabase.in/storage/v1/object/sign/site/profile_bg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzaXRlL3Byb2ZpbGVfYmciLCJpYXQiOjE2NDY3NjcyNjMsImV4cCI6MTk2MjEyNzI2M30.68NIAAaXyX2h_MHtwsZA6T96sL5etfraF4gkeLF7UWY');
          ">
            <span id="blackOverlay" class="w-full h-full absolute opacity-50 bg-black"></span>
        </div>
        <div class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style="transform: translateZ(0)">
            <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                version="1.1" viewBox="0 0 2560 100" x="0" y="0">
            </svg>
        </div>
    </section>
    <section class="relative py-16">
        <div class="container mx-auto px-4">
            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                <div class="px-6">
                    <div class="flex flex-wrap justify-center">
                        <div class="w-full lg:w-4/12 px-4 lg:order-2 flex justify-center">
                                <div [ngStyle]="{'background-image': 'url(' + profileData.user.avatar + ')'}"
                                    class="avatar-img shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 max-w-150-px">
                                </div>
                        </div>
                        <div class="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                            <div class="py-6 px-3 mt-32 sm:mt-0">
                                <div class="text-sm leading-normal mt-1 text-blueGray-400 font-bold lowercase">
                                    <i class="fab fa-whatsapp mr-2 text-lg text-blueGray-400"></i>
                                    {{profileData.user.phone}}
                                </div>
                                <div class="text-sm leading-normal mt-1 text-blueGray-400 font-bold lowercase">
                                    <i class="fas fa-envelope mr-2 text-lg text-blueGray-400"></i>
                                    {{profileData.user.email}}
                                </div>
                            </div>
                        </div>
                        <div class="w-full lg:w-4/12 px-4 lg:order-1">
                            <div class="flex flex-col justify-center py-4 lg:pt-4 pt-8">
                                <h6 class="text-sm font-semibold text-left w-full">Habilidades principais:</h6>
                                <div class="flex flex-row flex-wrap mt-2 w-full">
                                    <span *ngFor="let skill of convertToArray(profileData.skills)"
                                        style="font-size: 10px;"
                                        class="font-semibold inline-block mt-1 py-1 px-2 uppercase rounded text-white bg-indigo-500 last:mr-0 mr-1">
                                        {{skill}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-12">
                        <h3 class="text-4xl font-semibold leading-3 text-blueGray-700 mb-2">
                            {{profileData.user.full_name}}<br />
                            <small class="text-lg">{{profileData.headline}}</small>
                        </h3>
                        <div class="text-sm leading-normal mt-2 mb-2 text-blueGray-400 font-bold capitalize">
                            <i class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
                            {{profileData.user.city}}, {{profileData.user.state}}, Brasil
                        </div>
                        <div class="mb-2 text-blueGray-600 mt-2 ">
                            <i class="fas fa-clock mr-2 text-lg text-blueGray-400"></i>
                            Disponibilidade: <strong class="lowercase">{{profileData.availability}}</strong>
                        </div>
                        <div *ngIf="profileData.seniorityLevel" class="mb-2 text-blueGray-600 mt-2 ">
                            <i class="fas fa-clock mr-2 text-lg text-blueGray-400"></i>
                            Senioridade: <strong class="lowercase">{{profileData.seniorityLevel}}</strong>
                        </div>
                        <div class="mb-2 text-blueGray-600">
                            <i class="fas fa-dollar-sign mr-2 text-lg text-blueGray-400"></i>
                            <strong>{{profileData.minRate | currency : 'R$'}}</strong> a <strong>{{profileData.maxRate |
                                currency : 'R$'}} </strong> | {{rateTypeLabels[profileData.typeRate].label}}
                        </div>

                    </div>
                    <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                        <div class="flex flex-wrap justify-between">
                            <div class="w-full lg:w-6/12 px-4 text-left block whitespace-pre-line">
                                <strong>Sobre:</strong><br />
                                {{profileData.bio}}
                            </div>
                            <div class="w-full lg:w-6/12 px-4 text-left block whitespace-pre-line">
                                <strong>Links e Portfólios:</strong><br />
                                <small class="text-blueGray-600" style="font-size: 11px;">Links relevantes deste
                                    profissional</small>
                                <br /><br />
                                <div *ngFor="let link of profileData.links" class="mb-2 text-blueGray-600">
                                    <i class="fas fa-link mr-2 text-lg text-blueGray-400"></i>
                                    {{link}}
                                </div>
                                <!-- <ul *ngIf="workingExperienceList" class="">
                                    <li *ngFor="let wExp of workingExperienceList">
                                        <span class="text-sm font-semibold">{{wExp.title}}</span><br />
                                        <small style="font-size: 11px;"
                                            class="text-blueGray-600"><strong>{{wExp.company}}</strong>
                                            <span class="ml-1 mr-1">&#xb7;</span>
                                            <span>{{wExp.startedAt | date: 'dd/MM/yyy'}}</span> - 
                                            <span *ngIf="wExp.stillWorking">Hoje</span>
                                            <span *ngIf="!wExp.stillWorking">{{wExp.endAt | date: 'dd/MM/yyyy'}}</span>
                                        </small>
                                        <div class="text-left text-xs block whitespace-pre-line">
                                           {{wExp.description}}
                                        </div>
                                    </li>
                                </ul> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>