<app-auth-navbar></app-auth-navbar>
<main>
  <div
    class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75"
  >
    <div
      class="absolute top-0 w-full h-full bg-center bg-cover"
      style="
        background-image: url('https://ppcjgvkwowexttcrodgv.supabase.in/storage/v1/object/sign/site/site_bg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzaXRlL3NpdGVfYmciLCJpYXQiOjE2NDY3NjcyNjMsImV4cCI6MTk2MjEyNzI2M30.YNsIWPwhNG1zISESA-KT4smMLIcpOKci8owCTqBfis0');
      "
    >
      <span
        id="blackOverlay"
        class="w-full h-full absolute opacity-75 bg-black"
      ></span>
    </div>
    <div class="container relative mx-auto">
      <div class="items-center flex flex-wrap">
        <div class="w-full lg:w-8/12 px-4 pt-8 ml-auto mr-auto text-center">
          <div>
            <h1
              class="text-white font-semibold text-5xl"
              style="line-height: 60px"
            >
              A solução completa para o <br />
              <u class="font-bold" style="text-decoration-color: #f97316"
                ><span class="highlight"></span> do trabalho</u
              >
            </h1>
            <p class="pt-12 text-lg text-blueGray-200">
              <strong>Contrate</strong> e <strong>Gerencie</strong> Freelancers
              e Profissionais Independentes em minutos de forma fácil e segura.
              Com a <strong>INDII</strong> você automatiza a busca, o
              onboarding, a contratação, o faturamento e os pagamentos dos
              profissionais com facilidade, para que você possa se concentrar no
              que realmente importa, o seu negócio.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
      style="transform: translateZ(0)"
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="text-blueGray-200 fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
  </div>

  <section class="pb-20 bg-blueGray-200 -mt-24">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap">
        <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
          <div
            class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
          >
            <div class="px-4 py-5 flex-auto">
              <div
                class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-orange-600"
              >
                <i class="fas fa-award"></i>
              </div>
              <h6 class="text-xl font-semibold">Talentos & Freelancers</h6>
              <p class="mt-2 mb-4 text-blueGray-500">
                Tenha acesso a nossa exclusiva rede de freelancers, consultores
                e PJ's em áreas de grande demanda como Desenvolvimento, Design,
                Produto, SEO, DevOps, Marketing, Conteúdo, Recrutamento e muito
                mais.
              </p>
            </div>
          </div>
        </div>

        <div class="w-full md:w-4/12 px-4 text-center">
          <div
            class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
          >
            <div class="px-4 py-5 flex-auto">
              <div
                class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400"
              >
                <i class="fas fa-retweet"></i>
              </div>
              <h6 class="text-xl font-semibold">Pagamentos</h6>
              <p class="mt-2 mb-4 text-blueGray-500">
                Reduza o tempo gasto com o pagamento de freelancers, consultores
                e PJ's, e de a eles a melhor experiência possível ao trabalhar
                com sua empresa.
              </p>

              <span class="soon"
                ><i class="fas fa-star"></i>&ensp;Em Breve</span
              >
            </div>
          </div>
        </div>

        <div class="pt-6 w-full md:w-4/12 px-4 text-center">
          <div
            class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
          >
            <div class="px-4 py-5 flex-auto">
              <div
                class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400"
              >
                <i class="fas fa-fingerprint"></i>
              </div>
              <h6 class="text-xl font-semibold">Compliance</h6>
              <p class="mt-2 mb-4 text-blueGray-500">
                A INDII ajuda a proteger o seu negócio, automatizando o processo
                de contratação, assinatura de contratos (em breve) e onboarding de novos
                profissionais independentes.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="for_companies" class="flex flex-wrap items-center mt-32">
        <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
          <div
            style="background: #ea7c11"
            class="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
          >
            <i class="fas fa-rocket text-xl"></i>
          </div>
          <h3 class="text-3xl mb-2 font-semibold leading-normal">
            Oportunidades, Pagamentos e Onboarding em um só lugar
          </h3>
          <p
            class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
          >
            Economize tempo e trabalhe de forma mais inteligente. <br />
            Nossa plataforma oferece tudo o que você precisa para gerenciar sua
            equipe de profissionais independentes – desde a atração,
            contratação, onboarding, faturamento e pagamentos.
          </p>
          <h6 class="text-sm font-semibold text-blueGray-600">
            Um backoffice para sua Startup, Agência, Software House,
            Consultoria, etc.
          </h6>
          <p
            class="text-lg font-light leading-relaxed mt-2 mb-6 text-blueGray-600"
          >
            Dê à sua equipe uma visão centralizada de tudo o que eles precisam.
          </p>

          <ul class="list-none mt-6 mb-8">
            <li class="py-2">
              <div class="flex items-center">
                <div>
                  <span
                    class="text-sm mr-2 font-semibold inline-block py-1 px-2 uppercase rounded-full text-orange-500"
                  >
                    <i class="fas fa-check"></i>
                  </span>
                </div>
                <div>
                  <h4 class="text-blueGray-500">Rede exclusiva de talentos</h4>
                </div>
              </div>
            </li>
            
            <li class="py-2">
              <div class="flex items-center">
                <div>
                  <span
                    class="text-sm mr-2 font-semibold inline-block py-1 px-2 uppercase rounded-full text-orange-500"
                  >
                    <i class="fas fa-check"></i>
                  </span>
                </div>
                <div>
                  <h4 class="text-blueGray-500">
                   Payroll | Uma fatura para todos os profissionais
                  </h4>
                </div>
              </div>
            </li>
            <li class="py-2">
              <div class="flex items-center">
                <div>
                  <span
                    class="text-sm mr-2 font-semibold inline-block py-1 px-2 uppercase rounded-full text-orange-500"
                  >
                    <i class="fas fa-check"></i>
                  </span>
                </div>
                <div>
                  <h4 class="text-blueGray-500">Assinatura de contratos (em breve)</h4>
                </div>
              </div>
            </li>
            <li class="py-2">
              <div class="flex items-center">
                <div>
                  <span
                    class="text-sm mr-2 font-semibold inline-block py-1 px-2 uppercase rounded-full text-orange-500"
                  >
                    <i class="fas fa-check"></i>
                  </span>
                </div>
                <div>
                  <h4 class="text-blueGray-500">
                    White Label (em breve)
                  </h4>
                </div>
              </div>
            </li>
          </ul>
          <a
            href="#contactForm"
            class="font-bold text-xs uppercase px-4 py-2 rounded-md text-white mt-8 cursor-pointer"
            style="background: #ea7c11"
          >
            Quero saber mais&emsp;<i class="fa fa-paper-plane"></i>
          </a>
        </div>

        <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
          <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-orange-600"
          >
            <img
              alt="..."
              src="../../../assets/img/remoteTeam1.jpg"
              class="w-full align-middle rounded-t-lg"
            />
            <blockquote class="relative p-8 mb-4">
              <svg
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 583 95"
                class="absolute left-0 w-full block h-95-px -top-94-px"
              >
                <polygon
                  points="-30,95 583,95 583,65"
                  class="text-red-600 fill-current"
                ></polygon>
              </svg>
              <h4 class="text-xl font-bold uppercase text-white">
                Para Empresas
              </h4>
              <p class="text-md font-light mt-2 text-white">
                Aumente e gerencie sua equipe da maneira certa com a
                <b class="text-sm font-semibold">INDII</b>. <br /><br />
                Contrate, pague e gerencie facilmente freelancers e
                profissionais PJ em qualquer lugar do Brasil.
              </p>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
  </section>

  <section id="for_contractors" class="relative py-20">
    <div
      class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
      style="transform: translateZ(0)"
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="text-white fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>

    <div class="container mx-auto px-4">
      <div class="items-center flex flex-wrap">
        <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
          <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-indigo-500"
          >
            <img
              alt="..."
              src="../../../assets/img/single-freelancer.jpg"
              class="w-full align-middle rounded-t-lg"
            />
            <blockquote class="relative p-8 mb-4">
              <svg
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 583 95"
                class="absolute left-0 w-full block h-95-px -top-94-px"
              >
                <polygon
                  style="color: #6366f1"
                  points="-30,95 583,95 583,65"
                  class="fill-current"
                ></polygon>
              </svg>
              <h4 class="text-xl font-bold uppercase text-white text-right">
                Para Profissionais
              </h4>
              <p class="text-md font-light mt-2 text-white text-right">
                A maneira mais fácil e rápida de encontrar oportunidades como
                Freelancer e PJ de forma remota<br /><br />
                Junte-se à comunidade da
                <b class="text-sm font-semibold">INDII</b> para ter acesso a
                ofertas de emprego trabalhando em projetos de curto ou longo
                prazo para empresas de todo o Brasil.
              </p>
            </blockquote>
          </div>
        </div>
        <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
          <div class="md:pr-12">
            <div
              class="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-indigo-500"
            >
              <i class="fas fa-award text-xl"></i>
            </div>
            <h3 class="text-3xl font-semibold">Oportunidades Exclusivas</h3>
            <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
              Seja pago no prazo, mensalmente, quinzenalmente ou semanalmente
              por transferência bancária ou PIX diretamente em sua conta
              bancária.<br /><br />
              Utilizando nossa tecnologia de pagamentos e contratos nossos
              clientes deixam todo o fluxo de trabalho no piloto automático
              facilitando a vida do freelancer.
            </p>
            <ul class="list-none mt-6">
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-sm mr-2 font-semibold inline-block py-1 px-2 uppercase rounded-full text-indigo-600"
                    >
                      <i class="fas fa-check"></i>
                    </span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">
                      Oportunidades de curta ou longa duração
                    </h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-sm mr-2 font-semibold inline-block py-1 px-2 uppercase rounded-full text-indigo-600"
                    >
                      <i class="fas fa-check"></i>
                    </span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">
                      Trabalhe de qualquer lugar
                    </h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-sm mr-2 font-semibold inline-block py-1 px-2 uppercase rounded-full text-indigo-600"
                    >
                      <i class="fas fa-check"></i>
                    </span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">
                      Você escolhe com quem vai trabalhar
                    </h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-sm mr-2 font-semibold inline-block py-1 px-2 uppercase rounded-full text-indigo-600"
                    >
                      <i class="fas fa-check"></i>
                    </span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">Flexibilidade e autonomia</h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <!-- <button [routerLink]="['/signup/contractor']"
            class="font-bold text-xs inline-block uppercase px-4 py-2 mt-8 rounded-md text-white cursor-pointer"
            style="background: #6366f1">
            Faça parte&emsp;<i class="fa fa-paper-plane"></i>
          </button> -->
          <a
            target="_blank"
            href="https://8kph0gsq8p2.typeform.com/to/vTtWicXV"
            class="font-bold text-xs inline-block uppercase px-4 py-2 mt-8 rounded-md text-white cursor-pointer"
            style="background: #6366f1"
          >
            Faça parte&emsp;<i class="fa fa-paper-plane"></i>
          </a>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
  </section>
  <!-- 
  <section>
    <app-price></app-price>
  </section> -->

  <section class="bg-blueGray-200">
    <div class="container mx-auto px-4 pb-32 pt-24">
      <div class="items-center flex flex-wrap">
        <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
          <div class="md:pr-12">
            <div
              class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
            >
              <i class="fas fa-question text-xl"></i>
            </div>
            <h3 class="text-3xl font-semibold">
              <u style="text-decoration-color: #f97316">Como Funciona</u>
            </h3>
            <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
              A INDII permite que founders de startups, consultorias de
              tecnologia, grande e pequenas empresas contratem os melhores
              talentos globais, gerenciem pagamentos e contratos com
              facilidade.<br />
              Veja como é simples:
            </p>
            <ul class="list-none mt-6">
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-sm mr-2 font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-600"
                    >
                      1.
                    </span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">
                      Crie uma nova oportunidade.
                    </h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-sm mr-2 font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-600"
                    >
                      2.
                    </span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">
                      Convide profissionais para completar o onboarding.
                    </h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-sm mr-2 font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-600"
                    >
                      3.
                    </span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">
                      Faça upload do seu contrato e assine.
                    </h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-sm mr-2 font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-600"
                    >
                      4.
                    </span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">
                      O profissional envia suas faturas por meio da INDII de
                      acordo com a configuração do contrato.
                    </h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-sm mr-2 font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-600"
                    >
                      5.
                    </span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">
                      Revise as faturas e faça os pagamentos
                    </h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
          <img
            alt="..."
            class="max-w-full rounded-lg"
            style="
              transform: scale(1) perspective(1040px) rotateY(-11deg)
                rotateX(2deg) rotate(2deg);
            "
            src="../../../assets/img/mock_indii_site_v2.png"
          />
        </div>
      </div>
    </div>
  </section>

  <section class="pb-20 relative block bg-blueGray-800">
    <div
      class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
      style="transform: translateZ(0)"
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="text-blueGray-800 fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>

    <div id="contactForm" class="container mx-auto px-4 lg:pt-24 lg:pb-64">
      <div class="flex flex-wrap text-center justify-center">
        <div class="w-full lg:w-6/12 px-4">
          <h2 class="text-4xl font-semibold text-white">
            Tudo que você precisa
          </h2>
          <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
            Se você quer contratar um prestador de serviços em São Paulo e um
            Freelancer em Salvador ou vinte PJ's espalhados pelo Brasil, você
            pode gerenciar os contratos e pagamentos, todos juntos na sua conta
            <b>INDII</b>.<br /><br />

            Nossa plataforma facilita o crescimento da sua equipe remota.
            <br /><b>Pronto para começar?</b>
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="relative block py-24 lg:pt-0 bg-blueGray-800">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
        <div class="w-full lg:w-6/12 px-4">
          <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200"
          >
            <div class="flex-auto p-5 lg:p-10">
              <h4 class="text-2xl font-semibold">Fale com nosso time</h4>
              <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                Preencha este formulário e retornaremos em 24 horas.
                <br />
                Ou envie um email para <strong>contato@indii.com.br</strong>
              </p>

              <!-- INIT FORM -->
              <form
                [formGroup]="contactForm"
                name="contact"
                id="contact-form"
                method="post"
                (ngSubmit)="onSubmit()"
              >
                <input type="hidden" name="form-name" value="contact" />
                <p style="display: none">
                  <label
                    >Dont fill this out if you're human:
                    <input name="bot-field"
                  /></label>
                </p>
                <div class="relative w-full mb-3 mt-8">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="full-name"
                  >
                    Seu nome
                  </label>
                  <input
                    type="text"
                    name="name"
                    formControlName="name"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Insira seu nome"
                  />
                </div>

                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="email"
                  >
                    Seu Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    formControlName="email"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Insira seu Email"
                  />
                </div>

                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="message"
                  >
                    Assunto
                  </label>
                  <textarea
                    rows="4"
                    cols="80"
                    name="message"
                    formControlName="message"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                    placeholder="Fale um pouco sobre sua necessidade..."
                  ></textarea>
                </div>
                <div class="text-center mt-6">
                  <button
                    class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Enviar
                  </button>
                </div>
              </form>
              <!-- END FORM -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>
